import axios from "axios"
const API_URL = process.env.REACT_APP_API_BASE_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem("adminAuthToken")
    if (token) {
      config.headers["Authorization"] = token
      config.headers["ngrok-skip-browser-warning"] = true
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Redirect the user to the login page
      localStorage.removeItem("adminAuthToken")
      !localStorage.getItem("adminAuthToken") &&
        (window.location.href = "/login")
    }
    return Promise.reject(error)
  }
)

export async function GET(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function POST(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function PUT(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function DELETE(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}