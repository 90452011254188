import React, { useState, useEffect } from "react";
import { PUT, GET } from "../../helper/api_helper";
import {
  EDIT_EMAIL_LIST,
  SINGLE_EMAIL_LIST_URL,
} from "../../helper/url_helper";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../../contexts/DataContext";
import { toast } from "react-toastify";

const UpdateEmailGroup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: "", GMT_Offset: "" });
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState(null); 
  const { loading, setLoading, emailListId } = globalContext(); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (emailListId) {
          const responseData = await GET(
            `${SINGLE_EMAIL_LIST_URL}/${emailListId}`
          );
          const { name, GMT_Offset } = responseData?.data;
          setFormData({
            name,
            GMT_Offset,
          });
        }
      } catch (error) {
        console.log("Error fetching email data:", error);
      }
    };
    fetchData();
  }, [emailListId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsValid(true);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name.trim() === "" || formData.GMT_Offset.trim() === "") {
      setIsValid(false);
      setError("Both fields are required.");
      return;
    }
    setLoading(true);
    let response;
    try {
      let response = await PUT(`${EDIT_EMAIL_LIST}/${emailListId}`, formData);
      setFormData({ name: "", GMT_Offset: "" }); 
      toast.success(response?.meta?.message);
      navigate("/emaillist");
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="row mb-4">
            <div className="d-flex justify-content-between">
              <h2>Update Email Group</h2>
              <button
                type="button"
                className="text-capitalize btn btn-danger rounded-pill px-4"
                onClick={() => navigate("/emaillist")}
              >
                Back
              </button>
            </div>
          </div>
          <div className="row">
            <div className="border border-secondary p-4 rounded">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    className={`form-control px-3 ${
                      isValid ? "" : "is-invalid"
                    }`}
                    name="name"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="gmtOffset">GMT Offset</label>
                  <input
                    type="text"
                    id="gmtOffset"
                    className={`form-control px-3 ${
                      isValid ? "" : "is-invalid"
                    }`}
                    name="GMT_Offset" 
                     placeholder="GMT Offset (+05:30)"
                    value={formData.GMT_Offset}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>

                {error && <div className="text-danger mb-3">{error}</div>}

                <div className="text-center">
                  <button
                    type="submit"
                    className="text-capitalize btn btn-primary px-4 rounded-pill"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmailGroup;
