import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import {
  FETCH_SMTP_CREDENTIAL_URL,
  ADD_SMTP_CREDENTIAL_URL,
  UPDATE_SMTP_CREDENTIAL_URL,
} from "../../helper/url_helper";
import { GET, POST, PUT } from "../../helper/api_helper";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddUpdateSmtp = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [smtpLoading, setSmtpLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    friendly_name: "",
    smtp_host: "",
    smtp_port: "",
    imap_port: "",
    imap_host: null,
    username: "",
    password: "",
    imap_username: "", 
    imap_password: "", 
    from_email: "",
    from_name: "",
  });

  const [errors, setErrors] = useState({
    friendly_name: "",
    smtp_host: "",
    smtp_port: "",
    imap_port: "",
    imap_host: "",
    username: "",
    password: "",
    imap_username: "",  
    imap_password: "", 
    from_email: "",
    from_name: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showImapPassword, setShowImapPassword] = useState(false); 

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    setSmtpLoading(true);
    try {
      const responseData = await GET(`${FETCH_SMTP_CREDENTIAL_URL}/${id}`);
      setFormData({
        friendly_name: responseData?.data?.friendly_name || "",
        smtp_host: responseData?.data?.smtp_host || "",
        smtp_port: responseData?.data?.smtp_port || "",
        imap_port: responseData?.data?.imap_port || "",
        imap_host: responseData?.data?.imap_host || "",
        username: responseData?.data?.username || "",
        from_email: responseData?.data?.from_email || "",
        password: responseData?.data?.password,
        imap_username: responseData?.data?.imap_username || "",
        imap_password: responseData?.data?.imap_password || "",
        from_name: responseData?.data?.from_name || "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSmtpLoading(false);
    }
  };

  const validate = () => {
    let isValid = true;
    let validationErrors = {};
    

    if (!formData.friendly_name) {  // Validate friendly name
      validationErrors.friendly_name = "Friendly Name is required";
      isValid = false;
    }

    if (!formData.smtp_host) {
      validationErrors.smtp_host = "SMTP Host is required";
      isValid = false;
    }
    if (!formData.smtp_port) {
      validationErrors.smtp_port = "SMTP Port is required";
      isValid = false;
    }
    if (!formData.imap_host) {
      validationErrors.imap_host = "IMAP Host is required";
      isValid = false;
    }
    if (!formData.imap_port) {
      validationErrors.imap_port = "IMAP Port is required";
      isValid = false;
    }
    if (!formData.username) {
      validationErrors.username = "Username is required";
      isValid = false;
    }
    if (!formData.imap_username) {  
      validationErrors.imap_username = "IMAP Username is required";
      isValid = false;
    }
    if (!formData.from_email) {
      validationErrors.from_email = "From Email is required";
      isValid = false;
    }
    if (!formData.from_name) {
      validationErrors.from_name = "From Name is required";
      isValid = false;
    }

    // Only validate the password field if in update mode
    if (!formData.password) {
      validationErrors.password = "Kindly enter Password";
      isValid = false;
    }
    if (!formData.imap_password) {
      validationErrors.imap_password = "Kindly enter IMAP Password"; 
      isValid = false;
    }


    setErrors(validationErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        setSmtpLoading(true);
        let response;
        const formsdata={
          friendly_name:formData.friendly_name,
          smtp_host: formData.smtp_host,
          smtp_port: formData.smtp_port,
          imap_port: formData.imap_port,
          imap_host: formData.imap_host,
          username: formData.username,
          from_email: formData.from_email,
          from_name: formData.from_name,
          password: formData.password,
          imap_username:formData.imap_username,
          imap_password:formData.imap_password
        }
        const data = {
          ...formData, 
          password: isUpdate ? formData.password : undefined, 
          friendly_name:formData.friendly_name,
          imap_password: formData.imap_password 
        };

        if (isUpdate && id) {
          response = await PUT(`${UPDATE_SMTP_CREDENTIAL_URL}/${id}`, data);
        } else {
          response = await POST(`${ADD_SMTP_CREDENTIAL_URL}`, formsdata);
        }
        if (response.meta.status === 200) {
          toast.success(response?.meta?.message);
          navigate(`/smtp_credential`);
        } else {
          toast.error(response?.meta?.message);
        }
      } catch (error) {
        console.error("Error during submission:", error);
        toast.error(
          error?.response?.data?.meta?.message || "An error occurred"
        );
      } finally {
        setSmtpLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="mb-0">
                {isUpdate ? "Update SMTP Password" : "Add SMTP Credential"}
              </h4>
              <button
                className="btn btn-danger"
                onClick={() => navigate("/smtp_credential")}
              >
                Back
              </button>
            </div>
            <div className="border p-4 rounded">
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  name="friendly_name"
                  className="form-control mb-0"
                  placeholder="Enter friendly_name"
                  value={formData.friendly_name}
                  onChange={handleInputChange}
                  
                />
                {errors.friendly_name && (
                  <span className="text-danger">{errors.friendly_name}</span>
                )}
              </div>
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  name="smtp_host"
                  className="form-control mb-0"
                  placeholder="Enter SMTP Host"
                  value={formData.smtp_host}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.smtp_host && (
                  <span className="text-danger">{errors.smtp_host}</span>
                )}
              </div>
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="number"
                  name="smtp_port"
                  className="form-control mb-0"
                  placeholder="Enter SMTP Port"
                  value={formData.smtp_port}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.smtp_port && (
                  <span className="text-danger">{errors.smtp_port}</span>
                )}
              </div>

              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  name="username"
                  className="form-control mb-0"
                  placeholder="Enter Username"
                  value={formData.username}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.username && (
                  <span className="text-danger">{errors.username}</span>
                )}
              </div>
              <div
                className="form-group"
                style={{ position: "relative", marginBottom: "25px" }}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control mb-0"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  style={{ paddingRight: "40px" }}
                />
                <p
                  className="text-xl cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </p>
                {errors.password && (
                  <span
                    className="text-danger"
                    style={{
                      position: "absolute",
                      bottom: "-20px",
                      left: "0",
                    }}
                  >
                    {errors.password}
                  </span>
                )}
              </div>
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  name="imap_host"
                  className="form-control mb-0"
                  placeholder="Enter IMAP Host"
                  value={formData.imap_host}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.imap_host && (
                  <span className="text-danger">{errors.imap_host}</span>
                )}
              </div>
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="number"
                  name="imap_port"
                  className="form-control mb-0"
                  placeholder="Enter IMAP Port"
                  value={formData.imap_port}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.imap_port && (
                  <span className="text-danger">{errors.imap_port}</span>
                )}
              </div>
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  name="imap_username"
                  className="form-control mb-0"
                  placeholder="Enter IMAP Username"
                  value={formData.imap_username}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.imap_username && (
                  <span className="text-danger">{errors.imap_username}</span>
                )}
              </div>

              <div
                className="form-group"
                style={{ position: "relative", marginBottom: "25px" }}
              >
                <input
                  type={showImapPassword ? "text" : "password"}
                  name="imap_password"
                  className="form-control mb-0"
                  placeholder="Enter IMAP Password"
                  value={formData.imap_password}
                  onChange={handleInputChange}
                  style={{ paddingRight: "40px" }}
                />
                <p
                  className="text-xl cursor-pointer"
                  onClick={() => setShowImapPassword(!showImapPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showImapPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </p>
                {errors.imap_password && (
                  <span
                    className="text-danger"
                    style={{
                      position: "absolute",
                      bottom: "-20px",
                      left: "0",
                    }}
                  >
                    {errors.imap_password}
                  </span>
                )}
              </div>

              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="text"
                  name="from_name"
                  className="form-control basic-single mb-0"
                  placeholder="Enter From Name"
                  value={formData.from_name}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.from_name && (
                  <span className="text-danger">{errors.from_name}</span>
                )}
              </div>

              <div className="form-group" style={{ marginBottom: "10px" }}>
                <input
                  type="email"
                  name="from_email"
                  className="form-control basic-single mb-0"
                  placeholder="Enter From Email"
                  value={formData.from_email}
                  onChange={handleInputChange}
                  disabled={isUpdate}
                />
                {errors.from_email && (
                  <span className="text-danger">{errors.from_email}</span>
                )}
              </div>
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary" onClick={handleSubmit}>
                  {smtpLoading ? <Spinner /> : isUpdate ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddUpdateSmtp;
