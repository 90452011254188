import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { globalContext } from "../../contexts/DataContext";
import { GET, POST } from "../../helper/api_helper";
import {
  FETCH_TEMPLATE_LIST,
  RUN_CAMPAIGN_URL,
  VIEW_ALL_SIGNATURE_URL,
  VIEW_SIGNATURE_URL,
} from "../../helper/url_helper";
import { toast } from "react-toastify";

const ChildCampaign = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { loading, setLoading } = globalContext();
  const [selectedOption, setSelectedOption] = useState(null);
  const [signatureData, setSignaturedata] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [signatureOption, setSignatureOption] = useState({
    value: "0",
    label: "Select Signature",
  });
  const [tempData, setTempData] = useState([]);
  const [formData, setFormData] = useState({
    child_campaign_name: "",
    template_id: "",
  });
  const [errors, setErrors] = useState({
    child_campaign_name: "",
    template_name: "",
  });

  useEffect(() => {
    templateFetchData();
  }, []);

  const validate = () => {
    let isValid = true;
    let errors = {};
    if (!formData.child_campaign_name) {
      errors.child_campaign_name = "Kindly enter your Name";
      isValid = false;
    }
    if (!selectedOption) {
      errors.template_name = "Kindly select your Template";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const templateFetchData = async () => {
    try {
      setLoading(true);
      const templateData = await GET(FETCH_TEMPLATE_LIST);
      const formattedTemplateData = templateData?.data?.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setTempData(formattedTemplateData);
      const fetchSignatureData = await GET(`${VIEW_ALL_SIGNATURE_URL}`);
      const formattedSignatureData = fetchSignatureData?.data?.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setSignaturedata(formattedSignatureData);
    } catch (error) {
      console.log("error occurred while fetching email data", error);
    }
    setLoading(false);
  };

  const handleSelectChange = (type, option) => {
    setErrors({
      ...errors,
      template_name: "",
      signature_id: "",
    });
    type === "TEMPLATE" && setSelectedOption(option);
    type === "SIGNATURE" && setSignatureOption(option);

    if (type === "SIGNATURE") {
      if (option?.value) {
        fetchSignaturePreview(option.value);
      } else {
        setSignaturePreview(null);
      }
    }
  };

  const fetchSignaturePreview = async (signatureId) => {
    try {
      const response = await GET(`${VIEW_SIGNATURE_URL}/${signatureId}`);
      setSignaturePreview(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const data = {
          child_campaign_name: formData.child_campaign_name,
          template_id: selectedOption.value,
          campaign_id: campaignId,
          signature_id: signatureOption.value,
        };
        setLoading(true);
        let response = await POST(`${RUN_CAMPAIGN_URL}`, data);
        if (response.meta.status === 200) {
          toast.success(response?.meta?.message);
          navigate(`/campaigns/actions/campaignhistory/${campaignId}`);
        } else {
          toast.error(response?.meta?.message);
        }
      } catch (error) {
        toast.error(error?.response?.data?.meta?.message);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-end mb-3">
          <button
            className="btn btn-danger btn-content mr-2"
            style={{ width: "fit-content", marginRight: "10px" }}
            onClick={() => {
              navigate(`/campaigns/actions/campaignhistory/${campaignId}`);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <div className="border p-4 rounded">
              <div className="mb-2">
                <input
                  name="child_campaign_name"
                  className="form-control mb-0"
                  placeholder="Enter Name"
                  value={formData.child_campaign_name}
                  onChange={handleChange}
                />
                {errors.child_campaign_name && (
                  <span className="text-danger">
                    {errors.child_campaign_name}
                  </span>
                )}
              </div>
              <div className="mb-2">
                <Select
                  className=""
                  classNamePrefix="select"
                  name="template"
                  placeholder="Select Template"
                  value={selectedOption}
                  onChange={(option) => {
                    handleSelectChange("TEMPLATE", option);
                  }}
                  options={tempData}
                  isClearable
                  isSearchable
                />
                {errors.template_name && (
                  <span className="text-danger">{errors.template_name}</span>
                )}
              </div>
              <div className="mb-3">
                <Select
                  className=" mb-2"
                  classNamePrefix="select"
                  name="signature"
                  placeholder="Select Signature"
                  value={signatureOption}
                  onChange={(option) => {
                    handleSelectChange("SIGNATURE", option);
                  }}
                  options={signatureData}
                  isClearable
                  isSearchable
                />
                {signaturePreview && (
                  <div
                    className="mb-3 bg-white p-2 text-secondary"
                    style={{ border: "1px solid #dee2e6" }}
                    dangerouslySetInnerHTML={{ __html: signaturePreview }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChildCampaign;
