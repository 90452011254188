import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../EmailList/DataTable";
import { DELETE, GET } from "../../helper/api_helper";
import { DELETE_EMAIL_URL, VIEW_ALL_EMAIL_LIST } from "../../helper/url_helper";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { globalContext } from "../../contexts/DataContext";
import { PAGINATION } from "../../config/constant";

const ViewAllEmails = () => {
  const navigate = useNavigate();
  const [emailData, setEmailData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE); 
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  ); 
  const [totalData, setTotalData] = useState(0); 
  const [searchText, setSearchText] = useState("");

  const {
    emailListId,
    setEmailId,
    setViewEmail,
  } = globalContext();

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  const fetchData = async () => {
    const object = {
      limit: dataPerPage,
      searchText: searchText.trim(""),
      page: activepage,
    };
  
    const query = new URLSearchParams(object).toString();
    try {
      const responseData = await GET(
        `${VIEW_ALL_EMAIL_LIST}/${emailListId}/?${query}`
      );
      setTotalData(responseData?.meta?.total);
  
      if (responseData.data.length === 0) {
        setErrorMessage('You added a duplicate email, please add a unique emaillist');
      } else {
        setErrorMessage('');
        const allData = {
          columns: [
            { label: "No", field: "no", width: 150 },
            { label: "FirstName", field: "first_name", width: 150 },
            { label: "LastName", field: "last_name", width: 150 },
            { label: "Email", field: "email", width: 150 },
            {label:"Bounce Status",field:"is_bounced",width:150},
            { label: "Actions", field: "actions", sort: "disabled", width: 100 },
          ],
          rows: responseData.data.map((emails, index) => {
            const position = (activepage - 1) * dataPerPage + index + 1;
            const isBouncedText = emails.is_bounced === 1 ?  "❌" : "✅";

            return {
              ...emails,
              no: position,
              is_bounced: isBouncedText, 
              actions: (
                <>
                  <button
                    onClick={() => handleViewEmail(emails.id)}
                    className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                    style={{ color: "rgb(10, 54, 34, 0.5)" }}
                  >
                    <i className="fas fa-eye" title="View"></i>
                  </button>
                  <button
                    onClick={() => handleEditEmail(emails.id)}
                    className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                    style={{ color: "rgb(10, 54, 34, 0.5)" }}
                  >
                    <i className="fas fa-edit" title="Edit"></i>
                  </button>
                  <button
                    onClick={() => handleEmailDelete(emails.id)}
                    className="border-0 bg-transparent text-yellow fs-5 mr-2"
                    style={{ color: "rgb(88, 21, 28, 0.5)" }}
                  >
                    <i className="fas fa-trash" title="Delete"></i>
                  </button>
                </>
              ),
            };
          }),
        };
        setEmailData(allData);
      }
    } catch (error) {
      console.error("Error fetching email data:", error);
    }
  };
  

  useEffect(() => {
    if (!emailListId) {
      navigate(`/emaillist`);
    }
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    if (activepage || dataPerPage) {
      fetchData();
    }
    setEmailId(null);
  }, [activepage, dataPerPage]);

  const handleEditEmail = (emailId) => {
    navigate(`/emaillist/email/actions`);
    setEmailId(emailId);
  };

  const handleViewEmail = (emailId) => {
    navigate(`/emaillist/email/actions`);
    setEmailId(emailId);
    setViewEmail(true);
  };

  const handleEmailDelete = (emailId) => {
    try {
      Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const responseData = await DELETE(`${DELETE_EMAIL_URL}/${emailId}`);
          if (Number(responseData.meta.status) === 200) {
            fetchData();
            toast.success(responseData?.meta?.message);
          }
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container mx-auto">
          <div className="d-flex justify-content-end ">
            <button
              className="text-capitalize btn btn-primary"
              onClick={() => {
                setViewEmail(false);
                navigate(`/emaillist/email/actions`);
              }}
            >
              Add
            </button>
            <button
              className="text-capitalize btn btn-danger"
              onClick={() => navigate(`/emaillist`)}
            >
              Back
            </button>
          </div>
          <h2>Email Details</h2>
          {errorMessage ? (
            <div className="alert alert-danger">{errorMessage}</div>
          ) : (
            <DataTable
              title={""}
              limit={dataPerPage}
              totalRecords={totalData}
              tableData={emailData}
              handleActivePageChange={handleActivePageChange}
              handleSearchChange={handleSearchChange}
              handleEnteriesChange={handleEnteriesChange}
              text={"Emails"}
              loading={false}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
  
};

export default ViewAllEmails;
