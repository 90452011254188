import React, { useEffect, useState } from "react";
import DataTable from "../components/EmailList/DataTable";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DELETE_SMTP_CREDENTIAL_URL,
  SMTP_CREDENTIAL_PAGINATION_URL,
} from "../helper/url_helper";
import { DELETE, GET } from "../helper/api_helper";
import { globalContext } from "../contexts/DataContext";
import { PAGINATION } from "../config/constant";

const SmtpCredentailTableWithPagination = () => {
  const navigate = useNavigate();

  const [signatureData, setSignatureData] = useState();
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE);
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  );
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [smtpCredentialData, setSmtpCredentialData] = useState([]);
  const [isInitial, setIsInitial] = useState(true);

  const { setLoading } = globalContext();

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (!isInitial || searchText) {
          fetchData();
        }
      },
      searchText ? 500 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [activepage, dataPerPage, searchText]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      fetchData();
    }
  }, [isInitial]);

  const fetchData = async () => {
    setLoading(true);
    const object = {
      limit: dataPerPage,
      searchText: searchText.trim(""),
      page: activepage,
    };
    const query = new URLSearchParams(object).toString();

    const responseData = await GET(
      `${SMTP_CREDENTIAL_PAGINATION_URL}/?${query}`
    );
    setTotalData(responseData?.meta?.total);
    setLoading(false);
    const allData = {
      columns: [
        {
          label: "No",
          field: "no",
          width: 30,
        },
        {
          label: "FriendlyName",
          field: "friendly_name",
          width: 200,
        },
        {
          label: "SMTP Host",
          field: "smtp_host",
          width: 200,
        },
        {
          label: "IMAP Host",
          field: "imap_host",
          width: 100,
        },
        {
          label: "SMTP Port",
          field: "smtp_port",
          width: 100,
        },
        {
          label: "IMAP Port",
          field: "imap_port",
          width: 100,
        },
        {
          label: "SMTP Username",
          field: "username",
          width: 100,
        },
        {
          label: "IMAP Username",
          field: "imap_username",
          width: 100,
        },
        {
          label: "From Name",
          field: "from_name",
          width: 100,
        },
        {
          label: "From Email",
          field: "from_email",
          width: 100,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: responseData.data.map((credential, index) => {
        const position = (activepage - 1) * dataPerPage + index + 1;
        return {
          ...credential,
          no: position,

          actions: (
            <>
              <button
                onClick={() => handleEditCredential(credential.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgb(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-edit" title="Edit"></i>
              </button>

              <button
                onClick={() => handleDeleteCredential(credential.id)}
                className="border-0 bg-transparent text-yellow fs-5 mr-2"
                style={{ color: "rgb(88, 21, 28, 0.5)" }}
              >
                <i className="fas fa-trash" title="Delete"></i>
              </button>
            </>
          ),
        };
      }),
    };
    setSmtpCredentialData(allData);
    setLoading(false);
  };

  const handleEditCredential = (id) => {
    navigate(`/smtp_credential/edit/${id}`);
  };

  const handleDeleteCredential = async (Id) => {
    try {
      Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const responseData = await DELETE(
              `${DELETE_SMTP_CREDENTIAL_URL}/${Id}`
            );
            if (Number(responseData.meta.status) === 200) {
              fetchData();
              toast.success(responseData?.meta?.message);
            } else {
              toast.error(responseData?.meta?.message || "Deletion failed.");
            }
          } catch (error) {
            const errorMessage =
              error?.response?.data?.meta?.message ||
              "An error occurred during deletion. Please try again.";
            toast.error(errorMessage);
          }
        }
      });
    } catch (error) {
      const errorMessage =
        error?.response?.data?.meta?.message || "An unexpected error occurred.";
      toast.error(errorMessage);
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="d-flex justify-content-end mb-3">
          <button
            className="btn btn-primary rounded-pill px-4 h-10 text-white"
            onClick={() => navigate(`/smtp_credential/add`)}
          >
            Add
          </button>
        </div>
        <DataTable
          title={""}
          limit={dataPerPage}
          totalRecords={totalData}
          tableData={smtpCredentialData}
          handleActivePageChange={handleActivePageChange}
          handleSearchChange={handleSearchChange}
          handleEnteriesChange={handleEnteriesChange}
          text={"Credentail"}
          loading={false}
        />
      </div>
    </React.Fragment>
  );
};

export default SmtpCredentailTableWithPagination;
