import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../../contexts/DataContext";
import {
  ADD_SIGNATURE_URL,
  UPDATE_SIGNATURE_URL,
  VIEW_SIGNATURE_URL,
} from "../../helper/url_helper";
import { GET, POST, PUT } from "../../helper/api_helper";
import { toast } from "react-toastify";

const AddUpdateSignature = () => {
  const { loading, setLoading, viewSignature, signatureId } = globalContext();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    content: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    content: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (signatureId) {
        const responseData = await GET(`${VIEW_SIGNATURE_URL}/${signatureId}`);
        const { name, content } = responseData?.data;
        setFormData({
          name,
          content,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    if (!formData.name) {
      setErrors({
        ...errors,
        name: "Kindly Enter Your Name",
      });
      return false;
    }
    if (!formData.content) {
      setErrors({
        ...errors,
        content: "Kindly Enter content",
      });
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContentChange = (content) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      content: "",
    }));
    setFormData((prevData) => ({
      ...prevData,
      content: content,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        let responseData;
        setLoading(true);
        if (signatureId) {
          responseData = await PUT(
            `${UPDATE_SIGNATURE_URL}/${signatureId}`,
            formData
          );
        } else {
          responseData = await POST(`${ADD_SIGNATURE_URL}`, { ...formData });
        }
        if (responseData.meta.status === 200) {
          toast.success(responseData?.meta?.message);
          setFormData({ name: "", subject: "", content: "" });
          setErrors({});
          navigate(`/signature`);
        } else {
          setErrors(responseData?.meta?.message);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
      const newErrors = {};
      error?.inner?.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    } finally {
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <div className="container">
        <div className="row main-container col-8">
          <div className="col-12">
            <button
              className="bg-danger border-0 rounded-pill px-4 mb-3 float-end"
              style={{ height: "2.5rem", color: "white" }}
              onClick={() => navigate("/signature")}
            >
              Back
            </button>
          </div>
          <div className="col-12">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody className="data-table-card-body">
                      <div>
                        <input
                          className="input-field col-12"
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Name"
                          disabled={viewSignature}
                          style={{
                            cursor: viewSignature ? "not-allowed" : "auto",
                          }}
                        />
                        {errors.name && (
                          <span className="text-danger error">
                            {errors.name}
                          </span>
                        )}
                      </div>
                      <div className="col-12">
                        <ReactQuill
                          className={`quill-editor ${
                            viewSignature ? "disabled" : ""
                          }`}
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],
                              [{ size: [] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", "image", "video"],
                              [{ color: [] }, { background: [] }],  
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                            "image",
                            "video",
                            "color", 
                            "background", 
                          ]}
                          placeholder="Write your content here..."
                          name="content"
                          id="content"
                          value={formData.content}
                          onChange={handleContentChange}
                          readOnly={viewSignature}
                          style={{
                            cursor: viewSignature ? "not-allowed" : "auto",
                          }}
                        />
                        {/* for reactQuill ViewOnly */}
                        <style jsx>{`
                          .quill-editor.disabled {
                            cursor: not-allowed;
                          }
                          .quill-editor.disabled .ql-container,
                          .quill-editor.disabled .ql-editor,
                          .quill-editor.disabled .ql-editor * {
                            cursor: not-allowed !important;
                          }
                        `}</style>
                        {errors.content && (
                          <span className="text-danger error">
                            {errors.content}
                          </span>
                        )}
                      </div>
                      {!viewSignature && (
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary mt-3 border-0 rounded-pill px-4"
                            style={{ height: "2.5rem", color: "white" }}
                          >
                            {loading ? (
                              <Spinner />
                            ) : signatureId ? (
                              "Update"
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddUpdateSignature;
