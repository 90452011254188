import React from 'react';
import { Card, Button, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


import Breadcrumb from '../../src/layouts/AdminLayout/Breadcrumb/index';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { POST } from '../helper/api_helper'; 
import { ADMIN_LOGIN_URL } from '../helper/url_helper';


const SignIn = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  try {    
                    setSubmitting(true);                 
                    const response = await POST(ADMIN_LOGIN_URL, values);
                   if( response.meta.status === 200){
                    const token = response.meta.token; 
                    localStorage.setItem('adminAuthToken', token);
                    navigate('/dashboard')
                   }
                  } catch (error) {
                    console.error('Login failed:', error);
                    setErrors({ submit: 'Invalid email or password' });
                  }
                  setSubmitting(false);
                }}

              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <input
                        className="form-control  mb-1"
                        label="Email Address / Username"
                        name="email"
                        placeholder='Email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                      />
                      {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
                    </div>
                    <div className="form-group mb-4">
                      <input
                        className="form-control mb-1"
                        label="Password"
                        name="password"
                        placeholder='Password'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                      />
                      {touched.password && errors.password && <small className="text-danger form-text">{errors.password}</small>}
                    </div>

                    {errors.submit && (
                      <Col sm={12}>
                        <Alert variant="danger">{errors.submit}</Alert>
                      </Col>
                    )}
                    <Row>
                      <Col mt={2}>
                        <Button className="btn-block" color="primary" disabled={isSubmitting} size="large" type="submit" variant="primary">
                          Signin 
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
