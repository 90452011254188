import React, { useEffect, useState } from "react";
import DataTable from "../components/EmailList/DataTable";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DELETE_TEMPLATE_URL,
  TEMPLATE_PAGINATION_URL,
} from "../helper/url_helper";
import { DELETE, GET } from "../helper/api_helper";
import { globalContext } from "../contexts/DataContext";
import { PAGINATION } from "../config/constant";

const TemplateTableWithPagination = () => {
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState([]);
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE);
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  );
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { setLoading, setTemplateId, setViewTemplate } = globalContext();
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (!isInitial || searchText) {
          fetchData();
        }
        setTemplateId(null);
        setViewTemplate(false);
      },
      searchText ? 500 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [activepage, dataPerPage, searchText]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      fetchData();
    }
  }, [isInitial]);

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  const fetchData = async () => {
    setLoading(true);
    const object = {
      limit: dataPerPage,
      searchText: searchText.trim(""),
      page: activepage,
    };
    const query = new URLSearchParams(object).toString();

    const responseData = await GET(`${TEMPLATE_PAGINATION_URL}/?${query}`);
    setTotalData(responseData?.meta?.total);
    setLoading(false);
    const allData = {
      columns: [
        {
          label: "No",
          field: "no",
          width: 30,
        },
        {
          label: "Name",
          field: "name",
          width: 300,
        },
        {
          label: "Subject",
          field: "subject",
          width: 300,
        },
        {
          label: "Date",
          field: "created_date",
          width: 300,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: responseData.data.map((template, index) => {
        const position = (activepage - 1) * dataPerPage + index + 1;
        return {
          ...template,
          no: position,

          actions: (
            <>
              <button
                onClick={() => handleViewTemplate(template.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgb(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-eye" title="View"></i>
              </button>
              <button
                onClick={() => handleEditTemplate(template.id)}
                className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                style={{ color: "rgb(10, 54, 34, 0.5)" }}
              >
                <i className="fas fa-edit" title="Edit"></i>
              </button>

              <button
                onClick={() => handleDeleteTemplate(template.id)}
                className="border-0 bg-transparent text-yellow fs-5 mr-2"
                style={{ color: "rgb(88, 21, 28, 0.5)" }}
              >
                <i className="fas fa-trash" title="Delete"></i>
              </button>
            </>
          ),
        };
      }),
    };
    setTemplateData(allData);
    setLoading(false);
  };

  const handleEditTemplate = (templateId) => {
    navigate(`/template/actions`);
    setTemplateId(templateId);
  };

  const handleViewTemplate = (templateId) => {
    navigate(`/template/actions`);
    setTemplateId(templateId);
    setViewTemplate(true);
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const result = await Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      });
      if (result.isConfirmed) {
        setLoading(true);
        const responseData = await DELETE(
          `${DELETE_TEMPLATE_URL}/${templateId}`
        );
        if (Number(responseData.meta.status) === 200) {
          fetchData();
          toast.success(
            responseData?.meta?.message || "Template deleted successfully."
          );
          navigate("/templates");
        } else {
          toast.error(
            responseData?.meta?.message || "Failed to delete template."
          );
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.meta?.message ||
        "An error occurred during deletion.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <div className="d-flex justify-content-end mb-3">
            <button
              className="btn btn-primary rounded-pill px-4 h-10 text-white"
              onClick={() => {
                navigate(`/template/actions`);
              }}
            >
              Add
            </button>
          </div>
          <DataTable
            title={""}
            limit={dataPerPage}
            totalRecords={totalData}
            tableData={templateData}
            handleActivePageChange={handleActivePageChange}
            handleSearchChange={handleSearchChange}
            handleEnteriesChange={handleEnteriesChange}
            text={"Templates"}
            loading={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TemplateTableWithPagination;
