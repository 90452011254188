import React, { useEffect, useState } from "react";
import { GET, POST, PUT } from "../../helper/api_helper";
import {
  ADD_EMAIL_URL,
  UPDATE_EMAIL_URL,
  VIEW_EMAIL_URL,
} from "../../helper/url_helper";
import { toast } from "react-toastify";
import { Card, CardBody, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { globalContext } from "../../contexts/DataContext";

const AddEmail = () => {
  const navigate = useNavigate();
  const {loading,setLoading,emailListId,emailId,viewEmail,setViewEmail} = globalContext(); // Using From Global State

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const fetchData = async () => {
    try {
      const responseData = await GET(`${VIEW_EMAIL_URL}/${emailId}`);

      setFormData({
        first_name: responseData?.data?.first_name,
        last_name: responseData?.data?.last_name,
        email: responseData?.data?.email,
      });
    } catch (error) {
      console.log("error occured while fetching email data");
    }
  };

  useEffect(()=>{
    if(!emailListId){
      navigate(`/emaillist`)
    }
  })

  useEffect(() => {
    if (emailId) {
      fetchData();
    }
  }, [emailId]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      first_name: "",
      last_name: "",
      email: "",
    });
  };

  const validateData = () => {
    if (!formData.first_name) {
      setErrors({ ...errors, first_name: "Kindly enter your first name" });
      return false;
    }
    if (!formData.last_name) {
      setErrors({ ...errors, last_name: "Kindly enter your last name" });
      return false;
    }
    if (!formData.email) {
      setErrors({ ...errors, email: "Kindly enter your email" });
      return false;
    }
    if (formData.email && !EmailValidator.validate(formData.email)) {
      setErrors({ ...errors, email: "Kindly enter valid email" });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = validateData();
    if (result) {
      setLoading(true);
      let response;
  
      try {
        if (!emailId) {
          response = await POST(`${ADD_EMAIL_URL}/${emailListId}`, formData);
        } else {
          response = await PUT(`${UPDATE_EMAIL_URL}/${emailId}`, { ...formData, email_list_id: emailListId });
        }
  
        if (response.meta.status === 200) {
          toast.success(response?.meta?.message);
          navigate(`/emaillist/view`);
        } else {
          toast.error(response?.meta?.message);
        }
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
        });
        setErrors({
          email: "",
        });
      } catch (error) {
        toast.error(error?.response?.data?.meta?.message);
      } finally {
        setLoading(false);
      }
    }
  };
  

  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-center align-items-center ">
          <div className="row justify-content-end cancel-btn">
            <div className="col-md-auto  ">
              <button
                className="select-button btn btn-primary mb-5"
                onClick={() => {
                  navigate(`/emaillist/view`);
                  setViewEmail(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="col-12">
            <form>
              <Row>
                <Col className="col-12 d-flex justify-content-center">
                  <Card className="col-10 px-4">
                    <CardBody className="data-table-card-body">
                      <div>
                        <input
                          className={`input-field col-12 `}
                          style={{
                            cursor: viewEmail  ? "not-allowed" : "auto",
                          }}
                          viewEmail="text"
                          id="first_name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          placeholder="First name"
                          disabled={viewEmail}
                        />
                        {errors.first_name && (
                          <span className="text-danger">
                            {errors.first_name}
                          </span>
                        )}
                      </div>
                      <div>
                        <input
                          className={`input-field col-12`}
                          style={{
                            cursor: viewEmail ? "not-allowed" : "auto",
                          }}
                          viewEmail="text"
                          id="last_name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          placeholder="Last name"
                          disabled={viewEmail }
                        />
                        {errors.last_name && (
                          <span className="text-danger">
                            {errors.last_name}
                          </span>
                        )}
                      </div>
                      <div>
                        <input
                          className={`input-field col-12`}
                          style={{
                            cursor: viewEmail ? "not-allowed" : "auto",
                          }}
                          viewEmail="text"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Email"
                          disabled={viewEmail }
                        />
                        {errors.email && (
                          <span className="text-danger">{errors.email}</span>
                        )}
                      </div>
                      {!viewEmail  && (
                        <button
                          viewEmail="submit"
                          className="btn btn-primary mt-3"
                          onClick={handleSubmit}
                        >
                          {loading ? <Spinner /> : emailId ? "Update" : "Add"}
                        </button>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEmail;
