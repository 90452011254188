import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import "./TemplateComponentStyle.css";
import { POST, GET, PUT } from "../../helper/api_helper";
import {
  ADD_TEMPLATE_URL,
  VIEW_TEMPLATE_URL,
  UPDATE_TEMPLATE_URL,
} from "../../helper/url_helper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { globalContext } from "../../contexts/DataContext";

const CreateAndUpdateTemplateForm = () => {
  const navigate = useNavigate();
  const { templateId, loading, setLoading, viewTemplate } =
    globalContext();
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    content: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    subject: "",
    content: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    subject: Yup.string().trim().required("Subject is required"),
    content: Yup.string().trim().required("Content is required"),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContentChange = (content) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      content: "",
    }));
    setFormData((prevData) => ({
      ...prevData,
      content: content,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (templateId) {
        const responseData = await GET(`${VIEW_TEMPLATE_URL}/${templateId}`);
        const { name, subject, content } = responseData?.data;
        setFormData({
          name,
          subject,
          content,
        });
      }
    } catch (error) {
      console.log("useEffect ~ error:", error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check for empty fields
    const emptyFields = Object.keys(formData).filter(
      (key) => formData[key].trim() === ""
    );
  
    if (emptyFields.length > 0) {
      const newErrors = {};
      emptyFields.forEach((field) => {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      });
      setErrors(newErrors);
      return;
    }
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      let responseData;
      if (templateId) {
        responseData = await PUT(`${UPDATE_TEMPLATE_URL}/${templateId}`, formData);
      } else {
        responseData = await POST(`${ADD_TEMPLATE_URL}`, { ...formData });
      }
      if (responseData.meta.status === 200) {
        toast.success(responseData?.meta?.message);
        setFormData({ name: "", content: "" });
        setErrors({});
        navigate(`/templates`);
      } else {
        setErrors(responseData?.meta?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
      const newErrors = {};
      error?.inner?.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <React.Fragment>
      <div className="container">
        <div className="row main-container col-8">
          <div className="col-12">
            <button
              className="bg-danger border-0 rounded-pill px-4 mb-3 float-end"
              style={{ height: "2.5rem", color: "white" }}
              onClick={() => 
                navigate("/templates")
              }
            >
              Back
            </button>
          </div>
          <div className="col-12">
            <form onSubmit={handleSubmit}>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody className="data-table-card-body">
                      <div>
                        <input
                          className="input-field col-12"
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Name"
                          disabled={viewTemplate}
                          style={{
                            cursor: viewTemplate ? "not-allowed" : "auto",
                          }}
                        />
                        {errors.name && (
                          <span className="text-danger error">
                            {errors.name}
                          </span>
                        )}
                      </div>
                      <div>
                        <input
                          className="input-field col-12"
                          type="text"
                          id="subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          placeholder="Subject"
                          style={{
                            cursor: viewTemplate ? "not-allowed" : "auto",
                          }}
                          disabled={viewTemplate}
                        />
                        {errors.subject && (
                          <span className="text-danger error">
                            {errors.subject}
                          </span>
                        )}
                      </div>
                      <div className="col-12">
                        <ReactQuill
                          className="quill-editor"
                          modules={{
                            toolbar: [
                              [{ header: "1" }, { header: "2" }, { font: [] }],
                              [{ size: [] }],
                              [
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                              ],
                              [
                                { list: "ordered" },
                                { list: "bullet" },
                                { indent: "-1" },
                                { indent: "+1" },
                              ],
                              ["link", "image", "video"],
                              [{ color: [] }, { background: [] }],  
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "link",
                            "image",
                            "video",
                            "color", 
                            "background",
                          ]}
                          placeholder="Write your content here..."
                          name="content"
                          id="content"
                          value={formData.content}
                          onChange={handleContentChange}
                          style={{
                            cursor: viewTemplate ? "not-allowed" : "auto",
                          }}
                          readOnly={viewTemplate}
                        />

                        {errors.content && (
                          <span className="text-danger error">
                            {errors.content}
                          </span>
                        )}
                      </div>
                      {!viewTemplate && (
                      <div className="text-center">
                       <button
                          type="submit"
                          className="btn btn-primary mt-3 border-0 rounded-pill px-4"
                          style={{ height: "2.5rem", color: "white" }}
                        >
                          {loading ? (
                            <Spinner />
                          ) : templateId ? (
                            "Update"
                          ) : (
                            "Add"
                          )}
                        </button>
                        </div>
                      )}
                      <div style={{ marginTop: "18px" }}>
                        <p>
                          kindly use below variables to make template dynamic
                        </p>
                        <p>
                          <b>{"- {{first_name}}: "}</b>for adding first name
                        </p>
                        <p>
                          <b>{"- {{last_name}}: "}</b> for adding last name
                        </p>
                        <p>
                          <b>{"- {{user_email}}: "}</b> for adding user email
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateAndUpdateTemplateForm;
