// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.form-control {
  margin-bottom: 20px;
}

.input-field {
  margin-top: 20px; 
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.quill {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-container.ql-snow {
  border: none !important;
}


.ql-editor{
  height: 300px !important;
  border-top: 1px solid #ccc !important; 
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.error {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
}


`, "",{"version":3,"sources":["webpack://./src/components/Templates/TemplateComponentStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW;EACX,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,mCAAmC;EACnC,4BAA4B;EAC5B,sBAAsB;EACtB,sCAAsC;EACtC,wEAAwE;AAC1E;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;;AAGA;EACE,wBAAwB;EACxB,qCAAqC;EACrC,0BAA0B;EAC1B,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".main-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: auto;\n}\n\n.form-control {\n  margin-bottom: 20px;\n}\n\n.input-field {\n  margin-top: 20px; \n  display: block;\n  width: 100%;\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.5;\n  color: var(--bs-body-color);\n  appearance: none;\n  background-color: var(--bs-body-bg);\n  background-clip: padding-box;\n  border: 1px solid #ccc;\n  border-radius: var(--bs-border-radius);\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n}\n\n.quill {\n  border: 1px solid #ccc;\n  border-radius: 10px;\n  overflow: hidden;\n  margin-top: 20px;\n}\n\n.ql-toolbar.ql-snow {\n  border: none !important;\n}\n\n.ql-container.ql-snow {\n  border: none !important;\n}\n\n\n.ql-editor{\n  height: 300px !important;\n  border-top: 1px solid #ccc !important; \n  font-size: 1rem !important;\n  font-weight: 400 !important;\n  line-height: 1.5 !important;\n}\n\n.error {\n  padding-left: 10px;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
