import React, { useEffect, useState } from "react";
import DataTable from "../EmailList/DataTable";
import { useNavigate, useParams } from "react-router-dom";
import { globalContext } from "../../contexts/DataContext";
import { GET } from "../../helper/api_helper";
import { EMAILSTATUS_FETCH_URL } from "../../helper/url_helper";
import { PAGINATION } from "../../config/constant";
import {Tooltip} from "react-tooltip";

function EmailStatus() {
  const navigate = useNavigate();
  const { childCampaignId, campaignId } = useParams();
  const [totalData, setTotalData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE);
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  );
  const [searchText, setSearchText] = useState("");
  const { setLoading } = globalContext();

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  useEffect(() => {
    if (activepage || dataPerPage) {
      fetchData();
    }
  }, [activepage, dataPerPage]);

  const fetchData = async () => {
    setLoading(true);
    const object = {
      limit: dataPerPage,
      page: activepage,
      searchText: searchText.trim(""),
    };

    const query = new URLSearchParams(object).toString();
    const responseData = await GET(
      `${EMAILSTATUS_FETCH_URL}/${childCampaignId}?${query}`
    );

    setTotalData(responseData?.meta?.total);

    const allData = {
      columns: [
        { label: "No", field: "no", width: 150 },
        { label: "Name", field: "email", width: 150 },
        { label: "Status", field: "status", width: 150 },
        { label: "Read status", field: "is_read", width: 150 },
      ],
      rows: responseData.data.map((campaign, index) => {
        const position = (activepage - 1) * dataPerPage + index + 1;
        const statusSymbol = (status) => {
          switch (status) {
            case "delivered":
              return "✔";
            case "pending":
              return "⏳";
            case "bounced":
              return "❌";
            case "error":
              return "⚠️";
            default:
              return "❓";
          }
        };

        return {
          ...campaign,
          is_read: campaign.is_read ? "🟢" : "🔴",
          no: position,
          status: (
            <>
              <span
                id={`tooltip-${index}`}
                data-tooltip-content={campaign.error_reason}
              >
                {statusSymbol(campaign.status)}
              </span>
              <Tooltip anchorId={`tooltip-${index}`} />
            </>
          ),
        };
      }),
    };
    setCampaignData(allData);
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-end mb-3">
            <button
              type="button"
              className="text-capitalize btn btn-danger rounded-pill px-4"
              onClick={() =>
                navigate(`/campaigns/actions/campaignhistory/${campaignId}`)
              }
            >
              Back
            </button>
          </div>
          <DataTable
            title={""}
            limit={dataPerPage}
            totalRecords={totalData}
            tableData={campaignData}
            handleActivePageChange={handleActivePageChange}
            handleSearchChange={handleSearchChange}
            handleEnteriesChange={handleEnteriesChange}
            text={""}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EmailStatus;
