// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preview-container {
    border: 1px solid #ccc; 
    border-radius: 5px; 
    padding: 15px; 
    background-color: white; 
    margin-top: 20px; 
    width: 100%; 
  }
  
  .preview-header {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .preview-content {
    margin-left: 20px; 
    margin-top: 10px; 
  }
  
  .preview-label {
    font-weight: bold;
  }
  .preview-item {
    margin-top: 5px; 
  }

  .preview-additional-details{
   margin-top: 20px;
  }

  .data-green {
    color:blue; 
  }
  .header-red{
    color: green;
  }
`, "",{"version":3,"sources":["webpack://./src/components/campaign/SmtpCredentialPreview.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;;EAEA;GACC,gBAAgB;EACjB;;EAEA;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd","sourcesContent":[".preview-container {\n    border: 1px solid #ccc; \n    border-radius: 5px; \n    padding: 15px; \n    background-color: white; \n    margin-top: 20px; \n    width: 100%; \n  }\n  \n  .preview-header {\n    margin-top: 10px;\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  .preview-content {\n    margin-left: 20px; \n    margin-top: 10px; \n  }\n  \n  .preview-label {\n    font-weight: bold;\n  }\n  .preview-item {\n    margin-top: 5px; \n  }\n\n  .preview-additional-details{\n   margin-top: 20px;\n  }\n\n  .data-green {\n    color:blue; \n  }\n  .header-red{\n    color: green;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
