import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import { PAGINATION } from "../../config/constant";
import { Card, CardBody, Col, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import ReactSelect from "react-select";
import "./EmailPagination.css";
import DeleteEmail from "./DeleteEmail";

const DataTable = ({
  limit = PAGINATION.DEFAULT_DATA_PER_PAGE, // number of entries to display
  totalRecords = 0, // total number of records
  tableData = [], // table data
  handleActivePageChange, // handle active page number change
  handleSearchChange, // handle search input
  handleEnteriesChange,
  text, // text shown with limit entries dropdown
  loading,
  type = null,
  showTabs = false,
  activeTab,
  handleTabChange,
}) => {
  const [currentPage, setCurrentPage] = useState(
    Number(PAGINATION.DEFAULT_CURRENT_PAGE)
  );
  
  const totalPages = Math.ceil(totalRecords / limit);
  const firstRecord = (currentPage - 1) * limit + 1;
  const lastRecord = Math.min(currentPage * limit, totalRecords);

  // Function to handle page change
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    handleActivePageChange(page);
  };

  // Function to handle previous button click
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  // Function to handle next button click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };
 
  // Updated pagination render logic to show only current and adjacent page numbers
  const renderPagination = () => {
    const pages = [];

    // Show previous page if not on the first page
    if (currentPage > 1) {
      pages.push(
        <div
          key={currentPage - 1}
          className="p-2 border border-start-0 border-gray pointer-cursor bg-white"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {currentPage - 1}
        </div>
      );
    }

    // Show current page
    pages.push(
      <div
        key={currentPage}
        className="p-2 border border-start-0 border-gray pointer-cursor bg-primary text-white"
      >
        {currentPage}
      </div>
    );

    // Show next page if not on the last page
    if (currentPage < totalPages) {
      pages.push(
        <div
          key={currentPage + 1}
          className="p-2 border border-start-0 border-gray pointer-cursor bg-white"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {currentPage + 1}
        </div>
      );
    }

    return pages;
  };

  const options = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody className="data-table-card-body">
              {type === "EMAILIST" && <DeleteEmail />}
              {showTabs && (
                <div className="d-flex justify-content-start mb-3">
                  <span
                    className={`tab-title ${
                      activeTab === "active" ? "active-tab" : ""
                    }`}
                    onClick={() => {
                      handleTabChange("active"); // Use the passed prop to handle tab change
                      setCurrentPage(1); // Reset to first page on tab switch
                      handleSearchChange(""); // Reset search on tab change
                    }}
                  >
                    Active
                  </span>
                  <span
                    className={`tab-title ml-3 ${
                      activeTab === "completed" ? "active-tab" : ""
                    }`}
                    onClick={() => {
                      handleTabChange("completed"); // Use the passed prop to handle tab change
                      setCurrentPage(1); // Reset to first page on tab switch
                      handleSearchChange(""); // Reset search on tab change
                    }}
                  
                  >
                    Completed
                  </span>
                </div>
              )}
              <div className="datatable-entery-container w-fit-content d-flex justify-content-between mt-3">
                <label className="datatable-entery-label d-flex align-items-center">
                  Show
                  <ReactSelect
                    defaultValue={{ value: 50, label: 50 }}
                    onChange={(e) => handleEnteriesChange(e?.value)}
                    options={options}
                    isSearchable={false}
                    placeholder={PAGINATION.DEFAULT_DATA_PER_PAGE}
                    className="col-5 text-center mx-2 w-auto datatable-dropdown-select"
                  />
                  {text}
                </label>
              </div>

              {loading ? (
                <div className="loader-main">
                  <Spinner />
                </div>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={tableData}
                  searching={true}
                  searchLabel="Search"
                  infoLabel={["Showing", "to", "of", "entries"]}
                  noRecordsFoundLabel="No records found"
                  pagesAmount={10}
                  onPageChange={handlePageChange}
                  sortable={true}
                  noBottomColumns={true}
                  onSearch={handleSearchChange}
                  paging={false}
                />
              )}

              <div className="d-flex justify-content-between align-items-center bg-white">
                <div>
                  Showing {totalRecords === 0 ? 0 : firstRecord} to {lastRecord}{" "}
                  of {totalRecords} entries
                </div>
                <div className="d-flex justify-content-center align-items-center text-primary">
                  <button
                    disabled={currentPage < 2}
                    className={`border p-2 border-gray rounded-start-2 text-primary ${
                      currentPage < 2
                        ? "not-allowed-cursor"
                        : "pointer-cursor bg-white"
                    }`}
                    onClick={handlePreviousClick}
                  >
                    &lt;
                  </button>
                  {renderPagination()}
                  <button
                    disabled={totalPages === currentPage || totalPages === 1}
                    className={`border p-2 border-gray border-start-0 rounded-end-2 text-primary ${
                      totalPages === currentPage || totalPages === 1
                        ? "not-allowed-cursor"
                        : "pointer-cursor bg-white"
                    }`}
                    onClick={handleNextClick}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DataTable;
