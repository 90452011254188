import React from "react";
import { Navigate } from "react-router-dom";



const    Authmiddleware =({location,children})=>{
   if(!localStorage.getItem("adminAuthToken")){
    return <Navigate to={{pathname:"/login",state:{from:location}}}/>
   }

   return (
    <React.Fragment>
     

       {children}
    
    </React.Fragment>
   )
}

export default Authmiddleware