import React, { useEffect } from 'react';
import { authRoutes, userRoutes } from './routes';
import { Routes, Route, useNavigate } from "react-router-dom";

import Authmiddleware from './utils/AuthMiddler';
import AdminLayout from './layouts/AdminLayout';



const App = () => {
const navigate = useNavigate();
useEffect(()=>{
  const token = localStorage.getItem('adminAuthToken')
  if (!token){
    navigate('/login')
  }
},[])

  return (
    <React.Fragment>
      <Routes>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<React.Fragment>{route.element}</React.Fragment>}
              key={idx}
              exact={true}
            />
          ))}

        <Route>
          {userRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                element={<Authmiddleware>
                  <AdminLayout>
                  {route.element}
                  </AdminLayout>
                  </Authmiddleware>}
                key={idx}
                exact={true}
              />
            )
          })}
        </Route>

      </Routes>
    </React.Fragment>
  );
};

export default App;
