import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css"; 
import { useNavigate } from "react-router-dom";

const LogOut = () => {
    const [token, setToken] = useState(localStorage.getItem('adminAuthToken'));
    const navigate = useNavigate()

    const handleDeleteToken = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to log out. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('adminAuthToken');
                setToken(null);
                navigate("/login");
            }
            else{
                navigate("/dashboard");
            }
        });
    };

    useEffect(() => {
       
        handleDeleteToken ()

    }, []);

    return (
        <div className="container">
        </div>
    );
};

export default LogOut;
