import React, { createContext, useState, useContext } from "react";
import { PAGINATION } from "../config/constant";

// Create a context
export const GeneralContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE); //Use For active and setactive page
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  ); // Use For DataPerPage SetDataPerPage
  const [totalData, setTotalData] = useState(0); //Use For Store The All Pagination TableData
  const [searchText, setSearchText] = useState(""); //Use For Serchtext The All Pagination TableData
  const [emailListData, setEmailListData] = useState([]); //Use For Store The Emaillist Data
  const [viewEmailList, setViewEmailList] = useState(null); //Use For Store the VIEW_ALL_EMAIL_LIST Data
  const [loading, setLoading] = useState(false); //Use For Api Fetching Time
  const [emailListId, setEmailListId] = useState(null); //Set For EmailList And Emails Module
  const [emailId, setEmailId] = useState(null); // Set For Only Emails Module
  const [viewEmail, setViewEmail] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [viewCampaign, setViewCampaign] = useState(false);
  const [viewSignature,setViewSignature]=useState(false);
  const [signatureId,setSignatureId] = useState(null);
  const [runCampaign,setRunCampaign] = useState(false);
  const [childCampaignId,setChildCampaignId]= useState(null);
  const [smtpCredentialId, setSmtpCredentialId] = useState(null);

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };
   
  let value = {
    activepage,
    setActivePage,
    dataPerPage,
    setDataPerPage,
    totalData,
    setTotalData,
    searchText,
    setSearchText,
    emailListData,
    setEmailListData,
    viewEmailList,
    setViewEmailList,
    emailListId,
    setEmailListId,
    emailId,
    setEmailId,
    viewEmail,
    setViewEmail,
    viewCampaign,
    setViewCampaign,
    loading,
    setLoading,
    templateId,
    setTemplateId,
    viewTemplate,
    setViewTemplate,
    runCampaign,setRunCampaign,
    viewSignature,setViewSignature,
    signatureId,setSignatureId,
    handleActivePageChange,
    handleSearchChange,
    handleEnteriesChange,
    smtpCredentialId,
    setSmtpCredentialId
  };
   

  return (
    <GeneralContext.Provider value={value}>{children}</GeneralContext.Provider>
  );
};

export const globalContext = () => {
  const data = useContext(GeneralContext);
  return data;
};
