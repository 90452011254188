//LOGIN
export const ADMIN_LOGIN_URL = "/admin-api/v1/login";

//Email List
export const ADD_EMAIL_LIST_URL = "/admin-api/v1/email-list/upload";
export const EMAIL_LIST_PAGINATION_URL = "/admin-api/v1/email-list/all"; 
export const SINGLE_EMAIL_LIST_URL = "/admin-api/v1/email-list";
export const EMAIL_LIST_DELETE_URL = "/admin-api/v1/email-list";
export const EDIT_EMAIL_LIST = "/admin-api/v1/email-list";
export const FETCH_EMAIL_LIST = "/admin-api/v1/email-lists";

//Emails API
export const VIEW_ALL_EMAIL_LIST = "/admin-api/v1/emails/all"; // show all emails of specific email list
export const VIEW_EMAIL_URL = "/admin-api/v1/email";           // show specific email
export const ADD_EMAIL_URL = "/admin-api/v1/email";            // 
export const UPDATE_EMAIL_URL = "/admin-api/v1/email";
export const DELETE_EMAIL_URL = "/admin-api/v1/email";         //delete specific emails of emaillist 

//Template
export const TEMPLATE_PAGINATION_URL = "/admin-api/v1/templates/all";
export const DELETE_TEMPLATE_URL = "/admin-api/v1/template";
export const ADD_TEMPLATE_URL = "/admin-api/v1/template";
export const VIEW_TEMPLATE_URL = "/admin-api/v1/template";
export const UPDATE_TEMPLATE_URL = "/admin-api/v1/template";
export const FETCH_TEMPLATE_LIST = "/admin-api/v1/templates";

//Campaign
export const CAMPAIGN_PAGINATION_URL = "/admin-api/v1/campaigns/all";
export const VIEW_CAMPAIGN_URL = "/admin-api/v1/campaign";
export const UPDATE_CAMPAIGN_URL = "/admin-api/v1/campaign";
export const DELETE_CAMPAIGN_URL = "/admin-api/v1/campaign";
export const ADD_CAMPAIGN_URL = "/admin-api/v1/campaign";
export const CAMPAIGN_ACTION_URL = "/admin-api/v1/campaigns/actions"
export const RUN_CAMPAIGN_URL = "/admin-api/v1/run-child-campaign";
export const VIWE_CAMPAIGN_HISTORY = "/admin-api/v1/campaign-history";
export const RETRY_FAILED_EMAILS = "/admin-api/v1/campaign/retry-failed-emails";

//Delete All Email In EmailList
export const DELETE_ALL_EMAILIN_EMAILLIST = "/admin-api/v1/email-delete";
export const GET_All_UNIQUE_EMAIL = "/admin-api/v1//all-emails";

// Signature module APIs
export const ADD_SIGNATURE_URL = "/admin-api/v1/signature";
export const UPDATE_SIGNATURE_URL = "/admin-api/v1/signature";
export const VIEW_LIST_SIGNATURE_URL = "/admin-api/v1/signature/all";
export const VIEW_SIGNATURE_URL = "/admin-api/v1/signature";
export const VIEW_ALL_SIGNATURE_URL = "/admin-api/v1/signature";
export const DELETE_SIGNATURE_URL = "/admin-api/v1/signature";

//smtp credential
export const SETTING_FETCH_URL = "/admin-api/v1/settings";
export const SETTING_SAVE_ADD_URL = "/admin-api/v1/settings/save";

export const FETCH_SMTP_CREDENTIAL_URL = "/admin-api/v1/smtp-credential"
export const ADD_SMTP_CREDENTIAL_URL = "/admin-api/v1/smtp-credential"
export const UPDATE_SMTP_CREDENTIAL_URL = "/admin-api/v1/smtp-credential"
export const DELETE_SMTP_CREDENTIAL_URL ="/admin-api/v1/smtp-credential";
export const SMTP_CREDENTIAL_PAGINATION_URL = "/admin-api/v1/smtp-credentials";
export const FETCH_SMTP_CREDENTIALS_URL = "/admin-api/v1/smtp-credentialslist"


//CampaignHistory 
export const EMAILSTATUS_FETCH_URL = "/admin-api/v1/emailstatus"; 

//Admin Profile 
export const FETCH_ADMIN_PROFILE = "/admin-api/v1/fetch-admin-profile"
export const UPDATE_ADMIN_PROFILE = "/admin-api/v1/update-admin-profile"