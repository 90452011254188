import React, { useEffect, useState } from "react";
import { globalContext } from "../../contexts/DataContext";
import DataTable from "../EmailList/DataTable";
import { useNavigate, useParams } from "react-router-dom";
import { GET, POST } from "../../helper/api_helper";
import { RETRY_FAILED_EMAILS, VIWE_CAMPAIGN_HISTORY } from "../../helper/url_helper";
import { MdOutlineRefresh } from "react-icons/md";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { PAGINATION } from "../../config/constant";

const CampaignHistory = () => {
  const { campaignId } = useParams();
  const [campaignData, setCampaignData] = useState([]);
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE); 
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  ); 
  const [totalData, setTotalData] = useState(0); 
  const [searchText, setSearchText] = useState("");
  const {
    setLoading,
  } = globalContext();
  const navigate = useNavigate();
 
  
  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

   
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(false);
      if (searchText) {
        fetchData();
      } else {
        fetchData();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    setLoading(false);
    if (activepage || dataPerPage) {
      fetchData();
    }
    // setChildCampaignid(childCampaignid)
  }, [activepage, dataPerPage]);

  const fetchData = async () => {
    setLoading(true);
    const object = {
        limit: dataPerPage,
        page: activepage,
        searchText: searchText.trim(""),
    };
    const query = new URLSearchParams(object).toString();
    const responseData = await GET(
        `${VIWE_CAMPAIGN_HISTORY}/${campaignId}?${query}`
    );
    setTotalData(responseData?.meta?.total);
    
    const allData = {
        columns: [
            {
                label: "No",
                field: "no",
                width: 150,
            },
            {
                label: "Name",
                field: "child_campaign_name",
                width: 150,
            },
            {
                label: "Template",
                field: "template_name",
                width: 150,
            },
            {
              label: "Sent",  
              field: "total_sent_count",
              width: 100,
          },
            {
                label: "Read",
                field: "total_read_count",
                width: 100,
            },
            {
                label: "Error Emails",
                field: "total_error_count",
                width: 100,
            },
            {
              label: "Date",
              field: "created_at",
              width: 150,
          },
            {
                label: "Action",
                field: "actions",
                sort: "disabled",
                width: 100,
            },
        ],
        rows: responseData.data.map((data, index) => {
            const position = (activepage - 1) * dataPerPage + index + 1;
            const { id, campaign_id, created_at, child_campaign_name, template, total_read_count, total_error_count,  total_sent_count,signature_id } = data; 
            return {
                no: position,
                child_campaign_name: child_campaign_name,
                template_name: template ? template.name : "N/A",
                created_at: moment(created_at, "D/M/YYYY h:mm A").format("D/M/YYYY h:mm A"),
                total_read_count: total_read_count || 0,
                total_sent_count: total_sent_count || 0,
                total_error_count: total_error_count || 0,
                actions: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                            onClick={() => handleViewCampaign(id)}
                            className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                            style={{ color: "rgb(10, 54, 34, 0.5)" }}
                        >
                            <i className="fas fa-eye" title="View"></i>
                        </button>
                        <button
                            onClick={() => handleRetryFailedEmail(campaign_id, id, signature_id)} // Pass signature_id here
                            className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                            style={{
                                color: "rgb(10, 54, 34, 0.5)",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <MdOutlineRefresh title="Refresh" style={{ fontSize: "23" }} />
                        </button>
                    </div>
                ),
            };
        }),
    };

    setCampaignData(allData);
    setLoading(false);
};


  const handleViewCampaign = (childCampaignid) => {
    navigate(`/campaigns/actions/emailstatus/${campaignId}/${childCampaignid}`);
  };
const handleRetryFailedEmail = async (campaignId, campaignHistoryId,signature_id) => {
  Swal.fire({
      title: 'Retry email?',
      text: 'This action can’t be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, retry!',
  }).then(async (result) => {
      if (result.isConfirmed) {
          const payload = {
              campaign_id: campaignId,
              campaign_history_id: campaignHistoryId,
              signature_id: signature_id,
          };
          await POST(`${RETRY_FAILED_EMAILS}/`, payload);
      }
  });
};



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="d-flex justify-content-end mb-3">
            <button
              className="bg-primary border-0 rounded-pill px-4 me-2"
              style={{ height: "2.5rem", color: "white" }}
              onClick={() => {
                navigate(`/campaigns/actions/childcampaign/${campaignId}`);
              }}
            >
              Child Campaign
            </button>
            <button
              type="button"
              className="text-capitalize btn btn-danger rounded-pill px-4"
              onClick={() => navigate("/campaigns")}
            >
              Back
            </button>
          </div>
          <DataTable
            title={""}
            limit={dataPerPage}
            totalRecords={totalData}
            tableData={campaignData}
            handleActivePageChange={handleActivePageChange}
            handleSearchChange={handleSearchChange}
            handleEnteriesChange={handleEnteriesChange}
            text={""}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignHistory;
