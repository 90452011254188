// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;
  }
  
  .input-container input {
    padding-right: 40px; /* Ensure there's enough space for the icon */
  }
  
  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/Setting/Setting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,kBAAkB;EACpB;;EAEA;IACE,mBAAmB,EAAE,6CAA6C;EACpE;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,eAAe;IACf,UAAU;EACZ","sourcesContent":[".input-container {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    position: relative;\n  }\n  \n  .input-container input {\n    padding-right: 40px; /* Ensure there's enough space for the icon */\n  }\n  \n  .eye-icon {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    z-index: 1;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
