import React, { useState } from "react";
import vector from "../../assets/images/welcome/Vector.png";
import { FileUploader } from "react-drag-drop-files";
import "./FileUpload.css";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { POST } from "../../helper/api_helper";
import { toast } from "react-toastify";
import { ADD_EMAIL_LIST_URL } from "../../helper/url_helper";
import { Spinner } from "react-bootstrap";

const fileTypes = ["CSV", "XLSX"];

const FileUpload = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    gmtOffset:"",
    file: {},
  });
  const [error, setError] = useState({
    nameError: "",
    gmtOffsetError:"",
    fileError: "",
  });
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [fileLoading, setFileLoading] = useState(false);


  const handleChange = (file) => {
    if (file) {
      const fileName = file.name;
      setUploadedFileName(fileName);
    }

    setError({
      nameError: "",
      gmtOffsetError:"",
      fileError: "",
    });
    setFormData({ ...formData, file });
  };

  const validateData = () => {
    if (!formData?.name?.trim()) {
      setError({
        ...error,
        nameError: "Please provide a name.",
      });
      setUploadedFileName("");
      return false;
    }
    
    if (!formData?.gmtOffset?.trim()) {
      setError({
        ...error,
        gmtOffsetError: "Please provide GMT Time.",
      });
      setUploadedFileName("");
      return false;
    }
     
    if (!formData.file.name) {
      setError({
        ...error,
        fileError: "Please select a file.",
      });
      setUploadedFileName("");
      return false;
    }

    if (formData.file.name) {
      const fileName = formData.file.name;

      if (!fileName.endsWith(".csv") && !fileName.endsWith(".xlsx")) {
        setError({
          ...error,
          fileError: "Please select a CSV or Excel file.",
        });
        setUploadedFileName(""); 
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateData()) {
      setFileLoading(true);
      try {
        const responseData = await POST(ADD_EMAIL_LIST_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (responseData.meta.status === 200) {
          toast.success(responseData?.meta?.message);
          navigate(`/emaillist`);
          setFormData({
            name: "",
            gmtOffset:"",
            file: {},
          });
          setError({
            nameError: "",
            gmtOffsetError:"",
            fileError: "",
          });
          setUploadedFileName(""); 
        } else {
          toast.error(responseData?.meta?.message);
          setFormData({
            name: "",
            gmtOffset:"",
            file: {},
          });
          setError({
            nameError: "",
            gmtOffsetError:"",
            fileError: "",
          });
          setUploadedFileName("");
        }
      } catch (error) {
        toast.error(error?.response?.data?.meta?.message);
      } finally {
        setFileLoading(false); // Stop loading
        setFormData({
          name: "",
          gmtOffset:"",
          file: {},
        });
        setError({
          nameError: "",
          gmtOffsetError:"",
          fileError: "",
        });
        setUploadedFileName("");
      }
    }
  };
  
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center ">
        <div className="col-md-8 justify-content-end cancel-btn">
          <button
            className="select-button bg-danger border-0 rounded-pill px-4 mb-3"
            style={{ height: "2.5rem", color: "white" }}
            onClick={() => {
              navigate("/emaillist");
            }}
          >
            Back
          </button>
        </div>
        <div className="col-md-8 file-upload-container">
          <div
            className="p-4 border rounded "
            style={{ background: "#FFFFFF" }}
          >
            <div className="d-flex justify-content-end cross-icons">
              <RxCross2 style={{cursor:'pointer'}}
                onClick={() => {
                  navigate("/emaillist");
                }}
              />
            </div>

            <div className="mb-4 text-center">
              <input
                type="text"
                id="firstName"
                name="name"
                className="form-control"
                placeholder="Name"
                value={formData.name}
                onChange={(e) => {
                  setError({
                    nameError: "",
                    fileError: "",
                  });
                  setFormData({ ...formData, name: e.target.value });
                }}
                style={{ width: "50%", margin: "auto" }}
              />
              {error.nameError && (
                <div className="text-danger">{error.nameError}</div>
              )}
            </div>
            <div className="mb-4 text-center">
              <input
                type="text"
                id="gmt"
                name="gmt"
                className="form-control"
                placeholder="GMT Offset (+05:30)"
                value={formData.gmtOffset}
                onChange={(e) => {
                  setError({
                    nameError: "",
                    gmtOffsetError:"",
                    fileError: "",
                  });
                  setFormData({ ...formData, gmtOffset: e.target.value });
                }}
                style={{ width: "50%", margin: "auto" }}
              />
              {error.gmtOffsetError && (
                <div className="text-danger">{error.gmtOffsetError}</div>
              )}
            </div>
            <div className="drop-area mb-3 text-center">
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                styles={{ display: "none" }}
                accept=".csv, .xlsx"
              >
                <img src={vector} alt="" className="mb-3 m-auto " />
                <h4>Drag & drop Here </h4>
                <h5>or</h5>
                <button className="select-button btn btn-secondary mb-3">
                  Select File
                </button>
              </FileUploader>
              {error.fileError && (
                <div className="text-danger">{error.fileError}</div>
              )}
              {uploadedFileName && (
                <div className="text-success">
                  File "{uploadedFileName}" selected successfully.
                </div>
              )}
            </div>
            <div className="text-center">
              <button
                className="btn btn-primary py-2 px-5 border-0 rounded-pill "
                onClick={handleSubmit}
              >
                 {fileLoading ? <Spinner/> : "Upload File"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
