export const BASE_TITLE = ' | MailSystem';

export const CONFIG = {
  layout: 'vertical',
  collapseMenu: false,
  layoutType: 'menu-dark'
};

export const PAGINATION = {
  DEFAULT_CURRENT_PAGE:1,
  DEFAULT_DATA_PER_PAGE:50
}