import React from "react";

import SignIn from "../src/pages/SignIn";
import Dashboard from "./pages/Dashboard";
import EmailList from "./pages/EmailList";
import FileUpload from "./components/EmailList/FileUpload";
import Templates from "./pages/Templates.jsx";
import LogOut from "./pages/LogOut.jsx";
import ViewAllEmails from "./components/emails/ViewAllEmail.jsx";
import CreateAndUpdateTemplateForm from "./components/Templates/CreateAndUpdateTemplateForm.jsx";
import Campaign from "./pages/Campaign.jsx";
import AddUpdateCampaing from "./components/campaign/AddUpdateCampaign.jsx";
import AddEmail from "./components/emails/AddEmail.jsx";
import UpdateEmailGroup from "./components/emails/UpdateEmailGroup.jsx";
import ChildCampaign from "./components/campaign/ChildCampaign.jsx";
import CampaignHistory from "./components/campaign/CampaignHistory.jsx";
import Signature from "./pages/Signature.jsx";
import AddUpdateSignature from "./components/Signature/AddUpdateSignature.jsx";
import Setting from "./pages/Setting.jsx";
import EmailStatus from "./components/campaign/EmailStatus.jsx";
import SmtpCredential from "./pages/SmtpCredential.jsx";
import AddUpdateSmtp from "./components/Smtp/AddUpdateSmtp.js";

  

export const authRoutes = [
  {
    path: "/login",
    element: <SignIn />,
  },
];
export const userRoutes = [
  {
    path: "/dashboard", //dashboard
    element: <Dashboard />,
  },
  {
    path: "/fileupload",
    element: <FileUpload />,
  },
  {
    path: "/emaillist", //show all email list
    element: <EmailList />,
  },
  {
    path: "/emaillist/view",
    element: <ViewAllEmails/>, //view all emails
  },
  {
    path:"/emaillist/update",
    element:<UpdateEmailGroup /> //email list edit
  },
  {
    path: "/emaillist/email/actions", //add email data in email list add/update/view
    element: <AddEmail/>,
  },
  {
    path: "/signature",
    element: <Signature />, 
  },
  {
    path: "/signature/action",
    element: <AddUpdateSignature/>, 
  },
  {
    path: "/templates",
    element: <Templates />, 
  },
  {
    path: "/template/actions",
    element: <CreateAndUpdateTemplateForm />, // add/update/view template
  },
  {
    path: "/campaigns", //campaings paginations table
    element: <Campaign />,
  },
  {
    path: "/campaigns/actions/:campaignId", //Add Update Campaign
    element: <AddUpdateCampaing />,
  },
  {
    path: "/campaigns/actions", //Add  Campaign
    element: <AddUpdateCampaing />,
  },
  {
    path:"/campaigns/actions/campaignhistory/:campaignId",//view campaign
    element:<CampaignHistory/>
  },
  {
     path:"/campaigns/actions/childcampaign/:campaignId",
     element:<ChildCampaign/>
  },
  {
    path:"/campaigns/actions/childcampaign/:campaignId",
    element:<ChildCampaign/>
 },
  {
    path:"/campaigns/actions/emailstatus/:campaignId/:childCampaignId",
    element:<EmailStatus/>
 },
  {
    path: "/setting",
    element: <Setting />,
  },
  {
    path: "/smtp_credential",
    element: <SmtpCredential />,
  },
  {
    path: "/smtp_credential/add",
    element: <AddUpdateSmtp />,
  },
  {
    path: "/smtp_credential/edit/:id",
    element: <AddUpdateSmtp />,
  },
  {
    path: "/logout",
    element: <LogOut />,
  },
  {
    path: "*",
    element: <Dashboard />,
  },
];
