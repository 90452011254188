import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import App from "./App";

import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { DataContext, GlobalProvider } from "./contexts/DataContext";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider>
        <GlobalProvider>
          <ToastContainer />
          <App />
        </GlobalProvider>
      </ConfigProvider>
    </Provider>
  </BrowserRouter>
);
