// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-row{
 position: relative;
 top:-40px;
 
 @media  (max-width:500px) {
    position: unset;
 }
}

.page-content{
    width: 90%;
    margin: auto;
}

.table{
    --bs-table-bg:#f1f0f0 !important;
}

.table.dataTable thead tr th.sorting_asc::after {
  content: "\\25BC" !important;
}

.table.dataTable thead tr th.sorting_desc::after {
  content: "\\25B2" !important; 
}`, "",{"version":3,"sources":["webpack://./src/components/EmailList/EmailPagination.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,SAAS;;CAET;IACG,eAAe;CAClB;AACD;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".flex-row{\n position: relative;\n top:-40px;\n \n @media  (max-width:500px) {\n    position: unset;\n }\n}\n\n.page-content{\n    width: 90%;\n    margin: auto;\n}\n\n.table{\n    --bs-table-bg:#f1f0f0 !important;\n}\n\n.table.dataTable thead tr th.sorting_asc::after {\n  content: \"\\25BC\" !important;\n}\n\n.table.dataTable thead tr th.sorting_desc::after {\n  content: \"\\25B2\" !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
