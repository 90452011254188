import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { globalContext } from "../../contexts/DataContext";
import { GET, POST } from "../../helper/api_helper";
import { toast } from "react-toastify";
import {
  DELETE_ALL_EMAILIN_EMAILLIST,
  GET_All_UNIQUE_EMAIL,
} from "../../helper/url_helper";
import Swal from "sweetalert2";

const DeleteEmail = () => {
  const { setLoading } = globalContext();
  const [emailData, setEmailData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectStringOption, setSelectStringOption] = useState("");

  useEffect(() => {
    fetchEmailListData();
  }, []);

  const fetchEmailListData = async () => {
    try {
      setLoading(true);
      const emailListData = await GET(GET_All_UNIQUE_EMAIL);
      const formattedEmailListData = emailListData?.data?.map((email) => {
        return {
          value: email,
          label: email,
        };
      });
      setEmailData(formattedEmailListData);
    } catch (error) {
      console.log("error occurred while fetching email data", error);
    }
    setLoading(false);
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setErrorMessage("");

    const formattedOption = option?.map((data) => data.value);
    const selectStringOption = formattedOption.join(","); // Convert array to comma-separated string
    setSelectStringOption(selectStringOption);
  };

  const handleEmailListDelete = () => {
    if (!selectedOption || selectedOption.length === 0) {
      setErrorMessage("Please select an email.");
      return;
    }
    try {
      Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const responseData = await POST(`${DELETE_ALL_EMAILIN_EMAILLIST}`, {
            emails: selectStringOption,
          });
          if (Number(responseData.meta.status) === 200) {
            fetchEmailListData();
            toast.success(responseData?.meta?.message);
            setSelectedOption(null);
          }
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column align-items-center justify-content-center flex-sm-row justify-content-sm-center gap-4">
        <div className="w-auto text-center">
          <h6 className="text-base">
            Enter email to delete from all email_list:
          </h6>
        </div>
        <div className="w-auto w-sm-auto">
          <Select
            options={emailData}
            value={selectedOption}
            onChange={handleSelectChange}
            placeholder="Select an email..."
            isMulti
          />
          <div className="">
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </div>
        </div>
        <button
          className="btn btn-danger rounded-pill px-4 h-10"
          onClick={handleEmailListDelete}
        >
          delete
        </button>
      </div>
    </React.Fragment>
  );
};

export default DeleteEmail;
