// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-title {
    cursor: pointer;
    padding-bottom: 5px;
    font-weight: bold;
    margin-right: 20px;
  }

  .active-tab {
    border-bottom: 2px solid blue;
    color: blue; 
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Campaign.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,6BAA6B;IAC7B,WAAW;EACb","sourcesContent":[".tab-title {\n    cursor: pointer;\n    padding-bottom: 5px;\n    font-weight: bold;\n    margin-right: 20px;\n  }\n\n  .active-tab {\n    border-bottom: 2px solid blue;\n    color: blue; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
