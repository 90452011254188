import React, { useEffect, useState } from "react";
import DataTable from "../components/EmailList/DataTable";
import { globalContext } from "../contexts/DataContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE, GET } from "../helper/api_helper";
import {
  DELETE_SIGNATURE_URL,
  VIEW_ALL_SIGNATURE_URL,
  VIEW_LIST_SIGNATURE_URL,
  VIEW_SIGNATURE_URL,
} from "../helper/url_helper";
import Swal from "sweetalert2";
import { PAGINATION } from "../config/constant";

const Signature = () => {
  const navigate = useNavigate();
  const [signatureData, setSignatureData] = useState();
  const [activepage, setActivePage] = useState(PAGINATION.DEFAULT_CURRENT_PAGE);
  const [dataPerPage, setDataPerPage] = useState(
    PAGINATION.DEFAULT_DATA_PER_PAGE
  );
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { setLoading, setViewSignature, setSignatureId } = globalContext();
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (!isInitial || searchText) {
          fetchData();
        }
        setViewSignature(false);
        setSignatureId(null);
      },
      searchText ? 500 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [activepage, dataPerPage, searchText]);

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false);
      fetchData();
    }
  }, [isInitial]);

  const handleActivePageChange = (activePage) => {
    setActivePage(activePage);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleEnteriesChange = (limitValue) => {
    setDataPerPage(limitValue);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const object = {
        limit: dataPerPage,
        page: activepage,
        searchText: searchText.trim(""),
      };
      const query = new URLSearchParams(object).toString();
      const responseData = await GET(`${VIEW_LIST_SIGNATURE_URL}/?${query}`);
      setTotalData(responseData?.meta?.total);
      const allData = {
        columns: [
          { label: "No", field: "no", width: 150 },
          { label: "Name", field: "name", width: 150 },
          { label: "Date", field: "created_at", width: 300 },
          { label: "Actions", field: "actions", sort: "disabled", width: 100 },
        ],
        rows: responseData.data.map((signature, index) => {
          const position = (activepage - 1) * dataPerPage + index + 1;
          return {
            ...signature,
            no: position,
            actions: (
              <>
                <button
                  onClick={() => handleViewSignature(signature.id)}
                  className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                  style={{ color: "rgba(10, 54, 34, 0.5)" }}
                >
                  <i className="fas fa-eye" title="View"></i>
                </button>
                <button
                  onClick={() => handleEditSignature(signature.id)}
                  className="border-0 text-#626ED4 bg-transparent fs-5 mr-2"
                  style={{ color: "rgba(10, 54, 34, 0.5)" }}
                >
                  <i className="fas fa-edit" title="Edit"></i>
                </button>
                <button
                  onClick={() => handleDeleteSignature(signature.id)}
                  className="border-0 bg-transparent text-yellow fs-5 mr-2"
                  style={{ color: "rgba(88, 21, 28, 0.5)" }}
                >
                  <i className="fas fa-trash" title="Delete"></i>
                </button>
              </>
            ),
          };
        }),
      };

      setSignatureData(allData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewSignature = (signatureId) => {
    navigate(`/signature/action`);
    setSignatureId(signatureId);
    setViewSignature(true);
  };

  const handleEditSignature = (signatureId) => {
    navigate(`/signature/action`);
    setSignatureId(signatureId);
  };

  const handleDeleteSignature = async (signatureId) => {
    try {
      const result = await Swal.fire({
        title:
          "<span style='color: black; font-weight: bold;'>Are you sure?</span>",
        html: "<span style='color: black;'>You won't be able to revert this!</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "swal-confirm-button",
          cancelButton: "swal-cancel-button",
        },
      });
      if (result.isConfirmed) {
        setLoading(true);
        const responseData = await DELETE(
          `${DELETE_SIGNATURE_URL}/${signatureId}`
        );
        if (Number(responseData.meta.status) === 200) {
          fetchData();
          toast.success(
            responseData?.meta?.message || "Signature deleted successfully."
          );
        } else {
          toast.error(
            responseData?.meta?.message || "Failed to delete signature."
          );
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.meta?.message ||
        "An error occurred during deletion.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content ">
        <div className="container-fluid">
          <div className="d-flex justify-content-end mb-5">
            <button
              className="bg-primary border-0 rounded-pill px-4 "
              style={{ height: "2.5rem", color: "white" }}
              onClick={() => navigate(`/signature/action`)}
            >
              Add
            </button>
          </div>
          <DataTable
            title={""}
            limit={dataPerPage}
            totalRecords={totalData}
            tableData={signatureData}
            handleActivePageChange={handleActivePageChange}
            handleSearchChange={handleSearchChange}
            handleEnteriesChange={handleEnteriesChange}
            text={"Signature"}
            loading={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Signature;
