import React, { useEffect, useState } from "react";
import { GET, PUT } from "../helper/api_helper";
import { FETCH_ADMIN_PROFILE,UPDATE_ADMIN_PROFILE } from "../helper/url_helper";
import { toast } from "react-toastify";
import '../components/Setting/Setting.css'; 
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Spinner } from "react-bootstrap";

const Setting = () => {
  const [adminLoading,setAdminLoading]= useState(false);
  const [pass, setPass] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loginErrors, setLoginErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });


  useEffect(() => {
    fetchAdminProfileData();
  }, []);


  //fetching The Admin Profile Data
  const fetchAdminProfileData = async () => {
   
    setAdminLoading(true);
    try {
      const responseData = await GET(`${FETCH_ADMIN_PROFILE}`);
      
      if (responseData?.meta.status === 200) {
        setLoginData({
          email: responseData?.data?.email || "",
          password: responseData?.data?.password || "",
          confirmPassword: responseData?.data?.confirmPassword || "",
        });
      } else {
        toast.error(responseData?.meta?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
    }
    setAdminLoading(false);
  };

  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    setLoginErrors({ ...loginErrors, [name]: "" });
  };

  const validateLogin = () => {
    let isValid = true;
    const newErrors = { ...loginErrors };

    Object.keys(newErrors).forEach((key) => {
      newErrors[key] = "";
    });

    if (!loginData.email?.length) {
      newErrors.email = "Kindly enter email";
      isValid = false;
    }

    if (!loginData.password?.trim()) {
      newErrors.password = "Kindly enter password";
      isValid = false;
    }

    if (!loginData.confirmPassword?.trim()) {
      newErrors.confirmPassword = "Kindly enter Confirm Password";
      isValid = false;
    } else if (loginData.password !== loginData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setLoginErrors(newErrors);
    return isValid;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (validateLogin()) {
      setAdminLoading(true);
      try {
        const data = {
          email: loginData.email,
          password: loginData.password,
        };
        let response = await PUT(`${UPDATE_ADMIN_PROFILE}`, data);
        if (response.meta.status === 200) {
          setLoginData({
            email:response?.data?.email,
          })
          toast.success(response?.meta?.message);
        } else {
          toast.error(response.meta.message);
        }
      } catch (error) {
        toast.error(error?.response?.data?.meta?.message);
      }
      setAdminLoading(false);
    }
  };



  return (
    <React.Fragment>    
    <div className="container" style={{marginTop:"60px"}}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <h4>Admin Profile</h4>
          <div className="border p-4 rounded">
            <div className="form-group" style={{ marginBottom: loginErrors.email ? "10px" : "10px" }}>
              <input
                type="email"
                name="email"
                className="form-control mb-0"
                placeholder="Enter email"
                value={loginData.email}
                onChange={handleLoginInputChange}
              />
              {loginErrors.email && (
                <span className="text-danger d-block mt-2">
                  {loginErrors.email}
                </span>
              )}
            </div>

            <div className="form-group" style={{ marginBottom: loginErrors.password ? "10px" : "10px" }}>
              <div className="input-container">
                <input
                  type={pass ? "text" : "password"}
                  name="password"
                  className="form-control mb-0"
                  placeholder="Enter Password"
                  value={loginData.password}
                  onChange={handleLoginInputChange}
                />
                <p
                  className="eye-icon"
                  onClick={() => setPass(!pass)}
                >
                  {pass ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </p>
              </div>
              {loginErrors.password && (
                <span className="text-danger d-block mt-2">
                  {loginErrors.password}
                </span>
              )}
            </div>

            <div className="form-group" style={{ marginBottom: loginErrors.confirmPassword ? "10px" : "10px" }}>
              <div className="input-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  className="form-control mb-0"
                  placeholder="Enter Confirm Password"
                  value={loginData.confirmPassword}
                  onChange={handleLoginInputChange}
                />
                <p
                  className="eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </p>
              </div>
              {loginErrors.confirmPassword && (
                <span className="text-danger d-block mt-2">
                  {loginErrors.confirmPassword}
                </span>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleLoginSubmit}
                disabled={adminLoading}
              >
                {adminLoading ? <Spinner/> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default Setting;
