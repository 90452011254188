import { MdOutlineEmail } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { HiTemplate } from "react-icons/hi";
import { MdCampaign } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";



const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: <IoMdHome />,  
          url: '/dashboard'
        },
        {
          id: 'emaillist',
          title: 'Email List',
          type: 'item',
          icon: <MdOutlineEmail />,
          url: '/emaillist'
        },
        {
          id: 'signature',
          title: 'Signature',
          type: 'item',
          icon: <FaFileSignature />,
          url: '/signature'
        },
        {
          id: 'template',
          title: 'Tamplates',
          type: 'item',
          icon: <HiTemplate /> ,
          url: '/templates'
        },
        {
          id: 'campaigns',
          title: 'Campaign',
          type: 'item',
          icon: <MdCampaign />,
          url: '/campaigns'
        },
        {
          id: 'setting',
          title: 'Setting',
          type: 'item',
          icon: <IoIosSettings />,
          url: '/setting'
        },
        {
          id: 'smtp_credential',
          title: 'SMTP Credential',
          type: 'item',
          icon: <IoIosSettings />,
          url: '/smtp_credential'
        },
        {
          id: 'Logout',
          title: 'Logout',
          type: 'item',
          icon: <MdLogout />,
          url: '/logout'
        },
       
      ]
    },
  ]
};

export default menuItems;
