import React from "react";
import img from "../assets/images/welcome/welcome.png";

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="centered-container ">
        <div className="centered-content">
          <img
            src={img}
            className="centered-img img-fluid"
            alt="dashboard image"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
