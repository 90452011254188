import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { GET, POST, PUT } from "../../helper/api_helper";
import {
  ADD_CAMPAIGN_URL,
  FETCH_EMAIL_LIST,
  FETCH_TEMPLATE_LIST,
  UPDATE_CAMPAIGN_URL,
  VIEW_ALL_SIGNATURE_URL,
  VIEW_CAMPAIGN_URL,
  VIEW_SIGNATURE_URL,
  FETCH_SMTP_CREDENTIALS_URL,
  FETCH_SMTP_CREDENTIAL_URL,
} from "../../helper/url_helper";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { globalContext } from "../../contexts/DataContext";
import "./SmtpCredentialPreview.css";

const AddUpdate = () => {
  const { campaignId } = useParams();
  const { loading, setLoading } = globalContext();
  const [componentLoading, setComponentLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email_list_id: "",
    template_id: "",
    email_title: "",
    delay: "",
    signature: "",
    smtp_credential_id: "",
  });
  const [emaiList, setEmailList] = useState([]);
  const [template, setTemplate] = useState([]);
  const [signatureData, setSignatureData] = useState([]);
  const [smtpCredentials, setSmtpCredentials] = useState([]);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [selectedTemplateOption, setSelectedTemplateOption] = useState({
    value: 0,
    label: "Select template",
  });
  const [selectedEmailOption, setSelectedEmailOption] = useState({
    value: 0,
    label: "Select email list",
  });
  const [selectedSignature, setSelectedSignature] = useState({
    value: "0",
    label: "Select Signature",
  });
  const [selectedSmtpOption, setSelectedSmtpOption] = useState({
    value: 0,
    label: "Select SMTP Credential",
    username: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email_list_name: "",
    template_name: "",
    delay: "",
    smtp_credential: "",
  });
  const [selectedSmtpDetails, setSelectedSmtpDetails] = useState(null);

  useEffect(() => {
    if (campaignId) {
      fetchData();
    }
    fetchDropDownData();
  }, [campaignId]);

  // fetch data for viewing campaign
  const fetchData = async () => {
    setLoading(true);
    const responseData = await GET(`${VIEW_CAMPAIGN_URL}/${campaignId}`);
    if (responseData?.meta.status === 200) {
      setFormData({
        name: responseData.data.name,
        email_list_id: responseData.data.email_list.id,
        template_id: responseData.data.template.id,
        email_title: responseData.data.email_title,
        signature: responseData.data.signature,
        delay: responseData.data.delay,
        smtp_credential_id: responseData.data.smtp_credential_id,
      });

      setSelectedEmailOption({
        label: responseData?.data?.email_list?.name,
        value: responseData?.data?.email_list?.id,
      });

      setSelectedTemplateOption({
        label: responseData?.data?.template?.name,
        value: responseData?.data?.template?.id,
      });

      setSelectedSignature({
        label: responseData?.data?.signature?.name,
        value: responseData?.data?.signature?.id,
      });
    }
    setLoading(false);
  };

  const fetchDropDownData = async () => {
    try {
      setComponentLoading(true);
      const email_list_Data = await GET(FETCH_EMAIL_LIST);
      const formatedEmailData = email_list_Data?.data?.map((list) => {
        return {
          value: list.id,
          label: list.name,
        };
      });
      setEmailList(formatedEmailData);
      const templateData = await GET(`${FETCH_TEMPLATE_LIST}`);
      const formattedTemplateData = templateData?.data?.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setTemplate(formattedTemplateData);
      const fetchSignatureData = await GET(`${VIEW_ALL_SIGNATURE_URL}`);
      const formattedSignatureData = fetchSignatureData?.data?.map((data) => {
        return {
          value: data.id,
          label: data.name,
        };
      });
      setSignatureData(formattedSignatureData);
      const smtpCredentialData = await GET(FETCH_SMTP_CREDENTIALS_URL);
      const formattedSmtpData = smtpCredentialData?.data?.map((data) => {
        return {
          value: data.id,
          label: `${data.friendly_name}`,
          from_name: data.from_name,
        };
      });
      setSmtpCredentials(formattedSmtpData);
    } catch (error) {
      console.log("error occured while fetching email data");
    } finally {
      setComponentLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      name: "",
      email_list_name: "",
      template_name: "",
      email_title: "",
      delay: "",
    });
  };

  const handleSelect = (type, selectedOptions) => {
    setErrors({
      name: "",
      email_list_name: "",
      template_name: "",
      email_title: "",
    });
    type === "EMAIL_LIST" && setSelectedEmailOption(selectedOptions);
    type === "TEMPLATE_LIST" && setSelectedTemplateOption(selectedOptions);
    type === "SIGNATURE" && setSelectedSignature(selectedOptions);
    if (type === "SMTP_CREDENTIAL") {
      setSelectedSmtpOption({
        ...selectedOptions,
        from_name:
          smtpCredentials.find((smtp) => smtp.value === selectedOptions?.value)
            ?.from_name || "",
      });

      if (selectedOptions?.value) {
        fetchSmtpCredentialDetails(selectedOptions.value);
      } else {
        setSelectedSmtpDetails(null);
      }
    }
    if (type === "SIGNATURE") {
      if (selectedOptions?.value) {
        fetchSignaturePreview(selectedOptions.value);
      } else {
        setSignaturePreview(null);
      }
    }
  };

  const fetchSmtpCredentialDetails = async (smtpId) => {
    try {
      const response = await GET(`${FETCH_SMTP_CREDENTIAL_URL}/${smtpId}`);
      if (response?.meta?.status === 200) {
        setSelectedSmtpDetails(response.data); // Store the fetched details
      } else {
        toast.error("Error fetching SMTP Credential details");
      }
    } catch (error) {
      console.error("Error fetching SMTP Credential details:", error);
      toast.error("Failed to fetch SMTP Credential details");
    }
  };

  //fetch singal signature data for preview
  const fetchSignaturePreview = async (signatureId) => {
    try {
      const response = await GET(`${VIEW_SIGNATURE_URL}/${signatureId}`);
      setSignaturePreview(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    if (!formData.name) {
      setErrors({ ...errors, name: "Kindely enter your campaign name" });
      return false;
    }
    if (!selectedEmailOption.value) {
      setErrors({
        ...errors,
        email_list_name: "Kindely select your emailListName ",
      });
      return false;
    }
    if (!selectedTemplateOption.value) {
      setErrors({
        ...errors,
        template_name: "Kindely select your templateName",
      });
      return false;
    }
    if (!formData.delay || formData.delay < 501 || formData.delay > 5000) {
      setErrors({ ...errors, delay: "Kindly enter a delay time between 501 and 5000 milliseconds" });
      return false;
    }
    if (!selectedSmtpOption.value) {
      setErrors({
        ...errors,
        smtp_credential: "Kindly select your SMTP credential",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      setLoading(true);
      if (!campaignId) {
        if (validate()) {
          const data = {
            name: formData.name,
            email_list_id: selectedEmailOption.value,
            template_id: selectedTemplateOption.value,
            email_title: selectedSmtpOption.from_name,
            signature_id: selectedSignature.value,
            delay: parseFloat(formData.delay) || 0,
            smtp_credential_id: selectedSmtpOption.value,
          };
          response = await POST(`${ADD_CAMPAIGN_URL}/`, data);
        }
      } else {
        response = await PUT(`${UPDATE_CAMPAIGN_URL}/${campaignId}`, {
          name: formData.name,
        });
      }
      if (response.meta.status === 200) {
        toast.success(response?.meta?.message);
        navigate(`/campaigns`);
      } else {
        toast.error(response?.meta?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.meta?.message);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="container" style={{ width: "90%", margin: "0 auto" }}>
        <div className="d-flex justify-content-end mb-3">
          <button
            className="text-capitalize btn btn-danger"
            onClick={() => {
              navigate("/campaigns");
            }}
          >
            Back
          </button>
        </div>
        <div className="border p-4">
          <form>
            {campaignId ? (
              <div className="mb-3">
                <input
                  className="form-control mb-3 basic-single"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <span className="text-danger">{errors.name}</span>
                )}
              </div>
            ) : (
              <React.Fragment>
                {componentLoading ? (
                  <Spinner />
                ) : (
                  <React.Fragment>
                    <div className="mb-3">
                      <input
                        className="form-control mb-0 basic-single"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <Select
                        className="basic-single mb-0"
                        classNamePrefix="select"
                        name="email_list"
                        placeholder="Select email list"
                        value={selectedEmailOption}
                        onChange={(selectedOptions) =>
                          handleSelect("EMAIL_LIST", selectedOptions)
                        }
                        options={emaiList}
                        isClearable
                        isSearchable
                      />
                      {errors.email_list_name && (
                        <span className="text-danger">
                          {errors.email_list_name}
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="template"
                        placeholder="Template"
                        value={selectedTemplateOption}
                        onChange={(selectedOptions) =>
                          handleSelect("TEMPLATE_LIST", selectedOptions)
                        }
                        options={template}
                        isClearable
                        isSearchable
                      />
                      {errors.template_name && (
                        <span className="text-danger">
                          {errors.template_name}
                        </span>
                      )}
                    </div>

                    <div className="mb-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="signature"
                        placeholder="Select signature"
                        value={selectedSignature}
                        onChange={(selectedOptions) =>
                          handleSelect("SIGNATURE", selectedOptions)
                        }
                        options={signatureData}
                        isClearable
                        isSearchable
                      />
                    </div>
                    {signaturePreview && (
                      <div
                        className="mb-3 bg-white p-2 text-secondary"
                        style={{ border: "1px solid #dee2e6" }}
                        dangerouslySetInnerHTML={{ __html: signaturePreview }}
                      />
                    )}
                    <div className="mb-3">
                      <input
                        type="number"
                        className="form-control mb-0 basic-single"
                        name="delay"
                        placeholder="Delay In Milliseconds (Min - 500 | Max - 5000)"
                        value={formData.delay}
                        onChange={handleChange}
                        style={{
                          borderColor: errors.delay ? 'red' : '', 
                        }}
                      />
                      {errors.delay && (
                        <span className="text-danger">{errors.delay}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="smtp_credential"
                        placeholder="Select SMTP Credential"
                        value={selectedSmtpOption}
                        onChange={(selectedOptions) =>
                          handleSelect("SMTP_CREDENTIAL", selectedOptions)
                        }
                        options={smtpCredentials}
                        isClearable
                        isSearchable
                      />
                      {errors.smtp_credential && (
                        <span className="text-danger">
                          {errors.smtp_credential}
                        </span>
                      )}
                    </div>
                    {selectedSmtpDetails && (
                      <div className="preview-container">
                        {/* SMTP section */}
                        <div className="preview-header">
                          Friendly Name:{" "}
                          <span className="data-green">
                            {selectedSmtpDetails.friendly_name}
                          </span>
                        </div>
                        <div className="preview-header">SMTP</div>
                        <div className="preview-content">
                          <div className="preview-item">
                            <span className="preview-label">Host:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.smtp_host}
                            </span>
                          </div>
                          <div className="preview-item">
                            <span className="preview-label">Port:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.smtp_port}
                            </span>
                          </div>
                          <div className="preview-item">
                            <span className="preview-label">Username:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.username}
                            </span>
                          </div>
                        </div>

                        {/* IMAP section */}
                        <div className="preview-header">IMAP</div>
                        <div className="preview-content">
                          <div className="preview-item">
                            <span className="preview-label">Host:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.imap_host}
                            </span>
                          </div>
                          <div className="preview-item">
                            <span className="preview-label">Port:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.imap_port}
                            </span>
                          </div>
                          <div className="preview-item">
                            <span className="preview-label">Username:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.imap_username}
                            </span>
                          </div>
                        </div>

                        {/* Additional details */}
                        <div className="preview-additional-details">
                          <div className="preview-item">
                            <span className="preview-label">From Name:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.from_name}
                            </span>
                          </div>
                          <div className="preview-item">
                            <span className="preview-label">From Email:</span>{" "}
                            <span className="data-green">
                              {selectedSmtpDetails.from_email}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary mt-3"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Spinner /> : campaignId ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddUpdate;
